
import { Component, Vue } from "vue-property-decorator";

import { User } from "@/store/user/types";
import { Activity, ActualActivity, ContentAssignment, ContentItem, Phase, Program, StateType, GenericState } from "@/types";
import { ROOT_ACTIONS } from "@/store/actions";
import { nl, fr } from "date-fns/locale";
import { format } from "date-fns";
import { USER_GETTERS } from "@/store/user/getters";
import { collection, collectionGroup, doc, documentId, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import { firestore } from "@/firebase";

@Component({
  components: {
    ProgramTypeSpan: () =>
      import("@/components/typography/ProgramTypeSpan.vue"),
    ProgramFormulaSpan: () => import('@/components/typography/ProgramFormulaSpan.vue'),
    AcitivityTypeSpan: () =>
      import("@/components/typography/ActivityTypeSpan.vue"),
  },
})
export default class Home extends Vue {
  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  program: Program | null = null;
  phases: Phase[] = [];
  states: GenericState[] = []
  activities = [] as Activity[];
  actualActivities = [] as ActualActivity[];

  coach: User | null = null;
  contentAssignments = [] as ContentAssignment[];
  contentItems = {} as Record<string, ContentItem>;
  webinars = [] as any[];

  loading = true;

  noProgram = false;
  currentStep = 1;

  formatDate(date: string | Date) {
    return format(new Date(date), 'EEEE dd/MM/yyyy - hh:mm', {
      locale: this.user.language == 'fr' ? fr : nl,
    })
  }

  get activitiesToConfirm(){
    // if Screencast or Webinar is in the name, it should not be confirmed (case insensitive)
    return this.actualActivities.filter(e => !e.participantConfirmed && e.participantPresent && !/screencast|webinar/i.test(this.getActivityName(e.activityId)))
  }

  formatHours(hours: number) {
    let rhours = Math.floor(hours);
    let minutes = hours % 1;
    if (minutes === 0) return `${rhours}h`;

    let rminutes = Math.ceil(minutes * 60)

    return `${rhours}h${rminutes}m`
  }

  loadingConfirmation = '';


  getActualHoursForActivity(activity: Activity) {
    const hours = this.actualActivities?.filter(e => e.activityId === activity.id).map(e => e.actualHours).reduce((a, b) => a! + b!, 0);
    if (isNaN(hours as number)) return 0;
    return hours?.toFixed(0);
  }

  get isSuspended() {
    return this.program?.state === StateType.SUSPENDED
  }

  async mounted() {
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [])

    await this.fetchProgram();
    await this.fetchContentAssignments();
    await this.fetchWebinars();

    if (this.program) {
      const coach = await getDoc(doc(firestore, 'users', this.program.coach))
      this.coach = {
        ...coach.data(),
        id: coach.id
      } as User
    }
    this.loading = false;
  }

  async fetchProgram() {
    console.log(this.user.id)
    const programs = await getDocs(query(collection(firestore, 'programs'), where('participant', '==', this.user.id)))
    if (programs.size == 0) {
      this.noProgram = true;
      return;
    }

    this.phases = []
    this.activities = []
    this.actualActivities = []

    try {
      const fetchedProgram = await getDoc(doc(firestore, 'programs', programs.docs[0].id))
      const fetchedPhases = await getDocs(query(collection(firestore, 'phases'), where('programId', '==', fetchedProgram.id), orderBy('index', 'asc')))

      const fetchedStates = await getDocs(query(collection(firestore, 'program-states'), where('programId', '==', fetchedProgram.id), orderBy('date', 'asc')))


      for (let phase of fetchedPhases.docs) {
        this.phases.push({
          ...phase.data(),
          id: phase.id,
        } as Phase);
        const fetchedActivities = await getDocs(query(collection(firestore, 'activities'), where('phaseId', '==', phase.id)))
        for (let activity of fetchedActivities.docs) {
          const fetchedActualActivities = await getDocs(query(collection(firestore, 'actual-activities'), where('activityId', '==', activity.id)))
          this.activities.push({
            ...activity.data(),
            id: activity.id
          } as Activity)
          for (let actualActivity of fetchedActualActivities.docs) {
            this.actualActivities.push({
              ...actualActivity.data(),
              id: actualActivity.id
            } as ActualActivity)
          }
        }
      }



      this.program = {
        ...fetchedProgram.data(),
        id: fetchedProgram.id,
      } as Program

      console.log(this.actualActivities)


      this.states = fetchedStates.docs.map(e => {
        return {
          ...e.data(),
          id: e.id
        } as GenericState
      })


      if (this.program) {
        const coach = await getDoc(doc(firestore, 'users', this.program.coach))

        this.coach = {
          ...coach.data(),
          id: coach.id
        } as User
      }
    } catch (error) {
      console.log(error)
    }
  }

  async confirmActivity(actualActivityId: string | undefined) {
    if (!actualActivityId) return;
    this.loadingConfirmation = actualActivityId;
    await updateDoc(doc(firestore, 'actual-activities', actualActivityId), {
      participantConfirmed: true
    })

    // await this.fetchProgram();
    this.actualActivities.find(e => e.id === actualActivityId)!.participantConfirmed = true;

    this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
      color: "success",
      text: this.$t('activityConfirmed'),
    });

    this.loadingConfirmation = '';
    location.reload();
  }

  webinarSortDir = 'asc';

  get sortedWebinars() {
    if (this.webinarSortDir === 'asc') {
      return this.webinars.sort((a, b) => a.start.toDate().getTime() - b.start.toDate().getTime())
    } else {
      return this.webinars.sort((a, b) => b.start.toDate().getTime() - a.start.toDate().getTime())
    }
  }

  async fetchWebinars() {
    const attendees = await getDocs(query(collection(firestore, 'event-attendees'), where('user', '==', this.user.id)))
    for (let webinar of attendees.docs) {
      const webinarData = await getDoc(doc(firestore, 'webinars', webinar.data().webinarId))
      if (webinarData.exists()) {
        this.webinars.push({
          id: webinarData.id,
          ...webinarData.data(),
        })
      }
    }
  }

  async fetchContentAssignments() {
    try {
      const contentAssignments = await getDocs(query(collection(firestore, 'content-assignments'), where('user', '==', this.user.id)))
      if (contentAssignments.size == 0) return;

      const contentItems = await getDocs(query(collection(firestore, 'content-items'), where(documentId(), 'in', contentAssignments.docs.map(e => e.data().contentId))))

      for (let contentItem of contentItems.docs) {
        this.contentItems[contentItem.id] = {
          ...contentItem.data(),
          id: contentItem.id
        } as ContentItem
      }

      this.contentAssignments = contentAssignments.docs.map(e => {
        return {
          id: e.id,
          ...e.data(),
        } as ContentAssignment
      })
    } catch (error) {
      console.error(error)
      // this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
      //   color: 'error',
      //   text: 'Error'
      // })
    }
  }

  getActivityName(activityId: string) {
    return this.activities.find(e => e.id === activityId)?.name || ''
  }
}
